import React from 'react';

import { SEO } from 'gatsby-theme-thepuzzlers-intl';
import { injectIntl } from 'gatsby-theme-thepuzzlers-intl';

// Local Components
import { Spacer, HorizontalLine } from 'components';

import {
  Header,
  useRestaurantPageData,
  Menu,
  Description
} from 'sections/restaurantPage';
import { RunningText } from 'sections/landingPage';

// External Data
import { useKisRestaurantPageData } from 'boxenStopHooks';

const Restaurant = () => {
  const { seo, header, menu } = useRestaurantPageData();

  const {
    header: cmsHeader,
    menu: cmsMenu,
    description: cmsDescription
  } = useKisRestaurantPageData();

  return (
    <>
      <SEO {...seo} />
      <Header data={cmsHeader} staticData={header} />
      <Spacer
        height={[
          '15.2rem',
          '17.3rem',
          '15.7rem',
          '16.5rem',
          '21.9rem',
          '21.7rem'
        ]}
      />
      <HorizontalLine />
      <RunningText
        sx={{
          pt: ['2.4rem', '3.8rem', '6.4rem', '3.2rem', '6.4rem', '6.9rem']
        }}
      />
      <Menu data={cmsMenu} staticData={menu} />
      <Description data={cmsDescription} />
    </>
  );
};

export default injectIntl(Restaurant);
