import React from 'react';

// External
import { Paragraph } from '@thepuzzlers/pieces';

// Local Components
import { SectionWithSideLine, GridSpacer } from 'components';

// Helper Function
import { convertApiHtmlText } from 'boxenStopHooks/helper';

export const Description = ({ data: { description } }) => {
  return (
    <SectionWithSideLine>
      <TopSpacer />
      <TextBlock description={convertApiHtmlText(description.html)} />
      <BottomSpacer />
    </SectionWithSideLine>
  );
};

const TextBlock = ({ description }) => {
  return (
    <Paragraph
      sx={{
        linHeight: 1.5,
        fontFamily: 'primary.normal',
        fontSize: ['1.6rem', '1.7rem', '1.8rem', '1.6rem', '1.8rem', '2rem'],
        gridColumn: [
          '1/13',
          '2 / span 10',
          '4 / span 18',
          '5 / span 16',
          '6 / span 14',
          '6 / span 13'
        ],
        fontFeatureSettings: "'pnum' on, 'onum' on"
      }}
      dangerouslySetInnerHTML={{ __html: description }}
    />
  );
};

const TopSpacer = () => (
  <GridSpacer
    height={['16.4rem', '27.2rem', '25.8rem', '18.1rem', '20.2rem', '21.5rem']}
  />
);
const BottomSpacer = () => (
  <GridSpacer
    height={['15.4rem', '24rem', '21.7rem', '13.8rem', '21.9rem', '19.8rem']}
  />
);
