import React from 'react';

// External Components
import { Section, Heading, Paragraph } from '@thepuzzlers/pieces';

// Local Components
import { ImageContainer } from 'components';

// Helper Function
import { convertApiHtmlText } from 'boxenStopHooks/helper';

export const Header = ({
  data: { headline, description },
  staticData: { images }
}) => {
  return (
    <Section
      id="restaurant__header"
      sx={{
        position: 'relative',
        overflow: 'hidden'
      }}>
      <Headline headline={convertApiHtmlText(headline.html)} />
      <Description description={convertApiHtmlText(description.html)} />
      <Decoration images={images} />
    </Section>
  );
};

const Headline = ({ headline }) => {
  return (
    <Heading
      as="h1"
      sx={{
        fontFamily: 'primary.bold',
        lineHeight: 1.1,
        fontSize: ['4rem', '5.2rem', '6rem', '3.6rem', '5.4rem', '6.4rem'],
        textAlign: 'center',
        gridColumn: [
          '1/13',
          '2 / span 10',
          '3 / span 18',
          '6 / span 14',
          '5 / span 16',
          '6 / span 14'
        ],
        mt: ['25.8rem', '35.5rem', '34.6rem', '5.7rem', '19.1rem', '18rem'],
        '& > span': {
          fontFamily: 'primary.italic'
        }
      }}
      dangerouslySetInnerHTML={{ __html: headline }}
    />
  );
};

const Description = ({ description }) => {
  return (
    <Paragraph
      dangerouslySetInnerHTML={{ __html: description }}
      sx={{
        gridColumn: [
          '1/13',
          '2 / span 10',
          '3 / span 17',
          '3 / span 16',
          '1 / span 12',
          '1 / span 12'
        ],
        lineHeight: 1.5,
        fontFamily: 'primary.normal',
        fontSize: ['1.6rem', '1.7rem', '1.8rem', '1.6rem', '1.8rem', '2rem'],
        mt: ['41.5rem', '53.4rem', '48.4rem', '29rem', '34rem', '26.7rem']
      }}
    />
  );
};

const Decoration = ({ images }) => {
  return (
    <>
      <ImageContainer
        src={images[0].src}
        alt=""
        sx={{
          gridColumn: ['1/13', '1/13', '1/25', '1/25', '1/25', '1/25'],
          position: 'absolute',
          aspectRatio: '1.35',
          width: [
            '18.4rem',
            '20.9rem',
            '25.6rem',
            '16.6rem',
            '27.1rem',
            '30.8rem'
          ],
          top: ['5.6rem', '10.1rem', '7.5rem', '9.6rem', '25rem', '24.9rem'],
          left: [0, 0, 0, 0, '-2.7rem', 0]
        }}
      />
      <ImageContainer
        src={images[1].src}
        alt=""
        sx={{
          gridColumn: ['1/13', '1/13', '1/25', '1/25', '1/25', '1/25'],
          position: 'absolute',
          aspectRatio: '1.386/1',
          width: [
            '33.4rem',
            '45.3rem',
            '53.1rem',
            '31.1rem',
            '51.1rem',
            '57.9rem'
          ],
          top: ['46.7rem', '63.2rem', '58.4rem', '16rem', '35.7rem', '37.9rem'],
          right: ['-6.9rem', '-11.1rem', '-12.3rem', '-2.9rem', '-5.7rem', 0]
        }}
      />
    </>
  );
};
