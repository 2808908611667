import React from 'react';

// External Components
import { Heading, Paragraph, Box } from '@thepuzzlers/pieces';
import { NavigationLink } from 'gatsby-theme-thepuzzlers-intl';

// Local Components
import {
  SectionWithSideLine,
  ImageContainer,
  GridSpacer,
  TextAndArrow
} from 'components';

// Helper Function
import { convertApiHtmlText } from 'boxenStopHooks/helper';

export const Menu = ({
  data: { headline, description, menu_button_label, menu_button_to },
  staticData: { image, alt }
}) => {
  return (
    <SectionWithSideLine id="restaurant__menu">
      <TopSpacer />
      <HeadlineBlock
        headline={convertApiHtmlText(headline.html)}
        description={convertApiHtmlText(description.html)}
      />
      <ImageWithButton
        image={image}
        alt={alt}
        buttonText={menu_button_label}
        buttonTo={menu_button_to}
      />
    </SectionWithSideLine>
  );
};

const HeadlineBlock = ({ headline, description }) => {
  return (
    <Box
      sx={{
        gridColumn: [
          '1 / span 11',
          '2 / span 8',
          '4 / span 18',
          '4 / span 13',
          '13 / span 10',
          '13 / span 11'
        ],
        mt: ['5.1rem', '7.8rem', '9.1rem', '8rem', 0, 0],
        alignSelf: [null, null, null, null, 'center', 'center']
      }}>
      <Heading
        as="h2"
        sx={{
          fontFamily: 'primary.bold',
          lineHeight: 1.2,
          fontSize: ['3.2rem', '4rem', '4.4rem', '3.2rem', '3.6rem', '4.8rem'],

          '& > span': {
            fontFamily: 'primary.italic'
          }
        }}
        dangerouslySetInnerHTML={{ __html: headline }}
      />
      <Paragraph
        sx={{
          lineHeight: 1.5,
          fontSize: [
            '1.5rem',
            '1.6rem',
            '1.7rem',
            '1.5rem',
            '1.8rem',
            '1.8rem'
          ],
          mt: ['2.4rem', '2.6rem', '2.4rem', '2.4rem', '2.4rem', '2.4rem'],
          width: [null, null, '40.8rem', '100%', '100%', '41.6rem']
        }}>
        {description}
      </Paragraph>
    </Box>
  );
};

const ImageWithButton = ({ image, buttonText, buttonTo, alt }) => {
  return (
    <NavigationLink
      to={buttonTo}
      sx={{
        cursor: 'pointer',
        gridColumn: [
          '1/13',
          '4 / span 8',
          '9 / span 14',
          '12 / span 9',
          '3 / span 9',
          '3 / span 8'
        ],
        gridRow: [null, null, null, null, 2, 2],
        mt: ['8.3rem', '10.8rem', '8.9rem', '7.2rem', 0, 0]
      }}>
      <Box initial="initial" whileHover="animate">
        <ImageContainer
          src={image}
          alt={alt}
          sx={{
            aspectRatio: '1 / 1.17'
          }}
        />
        <TextAndArrow
          text={buttonText}
          sx={{
            mt: ['3.2rem', '3.3rem', '3.2rem', '2.4rem', '3.2rem', '3.2rem']
          }}
        />
      </Box>
    </NavigationLink>
  );
};

const TopSpacer = () => (
  <GridSpacer
    height={[null, null, null, null, '16.7rem', '11.9rem']}
    sx={{
      display: ['none', 'none', 'none', 'none', 'block', 'block']
    }}
  />
);
